import React, { useState, useEffect } from "react";

import { Router } from "@reach/router";

import { connect } from 'react-redux';
import { selectCenters } from 'redux/selectors/centers';

import CenterPage from "components/center-group/Center"
import useSetCenterCookie from 'hooks/use-set-center-cookie';

const Centers = (props) => {
  const { centers, location, hostname } = props;
  const [centerId, setCenterId] = useState('');

  useEffect(() => {
    setCenterId(`centre-${hostname}`);
  }, []);

  useSetCenterCookie(centerId);

  if (!centerId) {
    return null;
  }

  return (
    <Router path="/">
      <CenterPage centerId={centerId} path="/" />
    </Router>
  );
};

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
});

export default connect(mapStateToProps)(Centers);
