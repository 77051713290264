import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';

import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { getCenterById } from 'utils/getCentersBy';
import { centerCookieIds } from 'utils/constants';
import { getCookie } from 'api';
import Cookies from 'universal-cookie';

import Layout from 'components/layout';
import SEO from 'components/seo';

import CenterHeader from './center-header';
import OneTimeMessageBlock from './one-time-message/OneTimeMessageBlock';
import OneTimeMessageModal from './one-time-message';
import CenterEvents from './center-events/CenterEvents';
import CenterPresentation from './center-presentation/CenterPresentation';
import CenterOffers from './center-offers/CenterOffers';
import SimplePushBlock from '../home-group/simple-push-block/SimplePushBlock';
import ComplexPushBlock from '../home-group/complex-push-block/ComplexPushBlock';
import Reinsurance from '../home-group/reinsurance/Reinsurance';
import useGetCenters from 'hooks/use-get-centers';

const cookies = new Cookies();

const CenterPage = (props) => {
  const { centerId, location } = props;

  const [selectedCenter, setSelectedCenter] = useState(null);

  useEffect(() => {
    getSelectedCenterFromUrl();

  }, [location]);

  const getSelectedCenterFromUrl = () => {

    if (centerId) {
      setSelectedCenter(centerId);
    } else {
      const urlFragments = location?.pathname.split('/');
      setSelectedCenter(urlFragments[2]);
    }
  };

  return (
    <Layout location={location} selectedCenter={selectedCenter} >
      <OneTimeMessageModal selectedCenter={selectedCenter} />
      <CenterHeader selectedCenter={selectedCenter} />
      <OneTimeMessageBlock selectedCenter={selectedCenter} />
      <CenterEvents selectedCenter={selectedCenter} />
      <CenterPresentation selectedCenter={selectedCenter} />
      <CenterOffers selectedCenter={selectedCenter} />
      <SimplePushBlock selectedCenter={selectedCenter} />
      <ComplexPushBlock selectedCenter={selectedCenter} />
      <Reinsurance location={location} />
    </Layout>
  );
};
export default CenterPage;
